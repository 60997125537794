<template>
	<div class="row">
		<div class="col">
		    <form @submit.prevent="checkForm" class="mb-4">
		        <div class="row">
		            <div class="col-12" v-if="form_message !== ''">
		                <ErrorAlert :messageI18n="form_message" />
		            </div>
		        </div>
		        
		        <div class="row">
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label for="payment_method">{{ $t('payment.payment_method') }} *</label>
						<e-select
							v-show="show_payment_methods"
							id="payment_method"
							:class="{ 'is-invalid': errors && errors.indexOf('Method') > -1 }"
							v-model="payment.payment_method"
							:options="payment_methods"
							:loading="loading_method"
							track-by="paymentmethod_id"
							label="translated_option"
							:allow-empty="false"
						/>
					</div>
				</div>

					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label for="payment_date">{{ $t('payment.payment_date') }} *</label>
							<e-datepicker v-model="payment.payment_date"></e-datepicker>
						</div>
					</div>
				</div>

				<div class="row" v-if="display_bankcheck">
					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label for="payment_bank">{{ $t('payment.payment_bank') }}</label>
							<e-select
								id="payment_bank"
								v-model="payment.payment_bank"
								:options="payment_banks"
								track-by="paymentbank_label"
								label="paymentbank_label"
								:allow-empty="false"
							/>
						</div>
					</div>

					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label for="payment_bankcheck">{{ $t('payment.payment_bankcheck') }}</label>
							<input id="payment_bankcheck" class="form-control" v-model="payment.payment_bankcheck" :placeholder="this.getTrad('payment.payment_bankcheck_placeholder')">
						</div>
					</div>
				</div>

				<div v-if="diplay_message_cc" class="row">
					<div class="col">
						<b-alert show variant="warning">
							{{ getTrad('payment.montant_sup_cc', {x:payment.payment_method.balance}) }}<br>
							<a href="#" @click.prevent="appliqueSoldeCC">{{ $t('payment.appliquer_solde_cc') }}</a>
						</b-alert>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="payment_amount">{{ $t('payment.payment_amount') }} *</label>
							<input id="payment_amount" class="form-control" v-model="payment.payment_amount" :placeholder="this.getTrad('payment.payment_amount_placeholder')" :class="{ 'is-invalid': errors && errors.indexOf('Amount') > -1 }">
						</div>
					</div>
					<div class="col-12">
						<b-alert v-if="error_amount_avoir" variant="danger" show>
                            {{ $t('payment.positive_amount_avoir') }}
                        </b-alert>
						<b-alert v-if="error_avoir_negativ" variant="danger" show>
                            {{ $t('payment.to_negative_amount') }}
                        </b-alert>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="payment_comment">{{ $t('payment.payment_comment') }}</label>
							<textarea id="payment_comment" class="form-control" v-model="payment.payment_comment" :placeholder="this.getTrad('payment.payment_comment_placeholder')" rows="2"></textarea>
						</div>
					</div>
				</div>

				<div class="row" v-if="invoices_suppliers.length > 0">
					<div class="col">
						<p>
							<span v-if="invoices_suppliers.length == 1">{{ $t('payment.add_payment_for_invoice') }} :</span>
							<span v-else>{{ $t('payment.add_payment_for_invoice_multiple') }} :</span>
							<ul>
								<li v-for="invoice in invoices_suppliers" :key="invoice.suppliersocr_num">{{ invoice.suppliersocr_num }}</li>
							</ul>
						</p>
					</div>
				</div>
	        	<div v-if="!error_amount_avoir || !error_avoir_negativ" class="row">
		            <div class="text-center mt-3 col-12">
	            		<b-button variant="primary" @click.prevent="addPayment"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'plus']" /> {{ $t("action.ajouter") }}</b-button>
	            		<b-button class="ml-2" v-if="open_charge" variant="secondary" @click.prevent="skip"><font-awesome-icon :icon="['fal', 'times']" /> {{ $t('acte.trigger.ignorer') }}</b-button>
		            </div>
		        </div>
		    </form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Payment from "@/mixins/Payment.js"
	import Accounting from "@/mixins/Accounting.js"
	import _cloneDeep from 'lodash/cloneDeep'
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"


	export default {
		name: "InvoiceSupplierAddPayment",
		mixins: [Config, TableAction, Navigation, Payment, Accounting, Shutter, ShutterInvoice],
		props: {
			invoices_suppliers: {
				type: Array,
				default: () => {
					[]
				}
			},
			open_charge: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				form_message: '',
				payment_methods: this.getConfig('payment_method'),
				payment_methods_save: this.getConfig('payment_method'),
				payment_banks: this.getConfig('payment_bank'),
				loading_method: false,
				display_bankcheck: false,
				diplay_message_cc: false,
				payment: {
					payment_method: null,
					payment_date: new Date(),
					payment_bank: null,
					payment_amount: null,
				},
				accounting_plan: null,
				isLoadingAccountingPlan: false,
				errors: [],
				current_account: null,
				unpaid: [],
				table_busy: true,
				error_amount_avoir: false,
				error_avoir_negativ: false,
				show_payment_methods: true,
				processing: false,
				tiers_id: null
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				this.payment.payment_amount = this.invoices_suppliers.reduce((sum, invoice) => sum + invoice.suppliersocr_balance, 0)

				// Formatage payment banks
				this.payment_banks = this.payment_banks.map((bank) => {
					bank.paymentbank_label = bank.paymentbank_label + ' - ' + bank.paymentbank_code
					return bank
				})

				this.tiers_id = this.invoices_suppliers[0].suppliersocr_tiers
				this.accounting_plan = await this.loadAccountingPlanByEntity(this.invoices_suppliers[0].suppliersocr_entity)
				await this.currentAccount(true)
				await this.displayCurrentAccount()

				// Formatage payment methods
				this.payment_methods.map((option) => {
					let translated_option = this.getTrad('payment.payment_method_labels.' + option.paymentmethod_code.toLowerCase())
					if (option.paymentmethod_code == 'CC') {
						const regExp = /\(([^)]+)\)/;
						const matches = regExp.exec(option.paymentmethod_label);
						if(matches && matches[0]){
							translated_option += ' ' + matches[0]
						}
					}
					option.translated_option = translated_option
					return option
				})
				this.show_payment_methods = false
				this.$nextTick(() => {
					this.show_payment_methods = true
				})
			},
			async checkForm() {

			},

			async addPayment(){

				this.errors = []
				this.processing = true

				if(this.payment.payment_amount == null)
				{
					this.errors.push("Amount")
				}

				if(!Date.parse(this.payment.payment_date))
				{
					this.errors.push("Date")
				}

				if(this.payment.payment_method == null)
				{
					this.errors.push("Method")
				}

				if(this.invoices_suppliers.length == 0 && this.accounting_plan == null)
				{
					this.errors.push("Accounting Plan")
				}

				if(this.errors.length > 0)
				{
					this.processing = false
					return false
				}


				let result = null
				if(this.accounting_plan != null)
				{
					this.payment.accounting_plan = this.accounting_plan.accountingplan_id
				}
				this.payment.invoice_ids = this.invoices_suppliers.map(invoice => invoice.suppliersocr_id)
				this.payment.tiers_id = this.$sync.replaceWithReplicated('tiers', this.tiers_id)

				const temp_send = _cloneDeep(this.payment)
				temp_send.payment_method = this.payment.payment_method.paymentmethod_id
				temp_send.payment_bank   = this.payment.payment_bank ? this.payment.payment_bank.paymentbank_id : null
				result = await this.createPaymentSupplier(temp_send)
				
				if(result) {
					this.processing = false
					this.successToast()
					this.ok()
					this.shutterPanel().close('invoice-supplier-payment')
					if(this.open_charge) {
						this.setupChargeAccountSupplier(this.invoices_suppliers[0], this.open_charge)
					}
					return true
				}
				else {
					this.processing = false
				}
				
			},

			async currentAccount(){
				this.loading_method = true
				this.current_account = await this.getCurrentAccountSupplier(this.tiers_id)
				this.loading_method = false
			},

			async displayCurrentAccount(){
				const index_current_account = this.payment_methods.findIndex(elem => elem.paymentmethod_code == "CC")
				this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (0)"
				this.payment_methods[index_current_account].balance = 0

				if(this.current_account && Object.keys(this.current_account).length > 0) {
					if(this.accounting_plan) {
						if(this.current_account[this.accounting_plan.accountingplan_label]) {
							const balance = this.current_account[this.accounting_plan.accountingplan_label].balance ?? 0
							const currency = this.current_account[this.accounting_plan.accountingplan_label].currency ?? 'EUR'
							this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (" + (balance / 100) + " " + currency + ")"
							this.payment_methods[index_current_account].balance = balance / 100
						}
					}
					else {
						if(this.current_account[this.invoice_accountingplans[0]]) {
							const balance = this.current_account[this.invoice_accountingplans[0]].balance ?? 0
							const currency = this.current_account[this.invoice_accountingplans[0]].currency ?? 'EUR'
							this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (" + (balance / 100) + " " + currency + ")"
							this.payment_methods[index_current_account].balance = balance / 100
						}
					}
				}
			},

			async removeCurrentAccountMethod(){
				const index_current_account = this.payment_methods.findIndex(elem => elem.paymentmethod_code == "CC")
				this.payment_methods.splice(index_current_account, 1);
			},
			checkCC() {
				if(this.payment.payment_method.balance < parseFloat(this.payment.payment_amount))
				{
					this.diplay_message_cc = true
				}
			},

			appliqueSoldeCC() {
				this.payment.payment_amount = this.payment.payment_method.balance
			},

			skip() {
				this.ok()
				this.shutterPanel().close('invoice-supplier-payment')
				this.setupChargeAccountSupplier(this.invoices_suppliers[0], this.open_charge)
			}

		},
		watch:{
			'payment.payment_method'(val) {
				this.display_bankcheck = false
				this.diplay_message_cc = false
				
				if(val.paymentmethod_code == "CH") {
					this.display_bankcheck = true
				}
				else if(val.paymentmethod_code == "CC") {
					this.checkCC()
				}
			},
			'payment.payment_amount'(val) {
				this.error_amount_avoir = val > 0 && this.invoice_balances < 0
				this.error_avoir_negativ = val < 0 && this.invoice_balances < 0 && val < this.invoice_balances
			}
		},
		components: {
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}
</script>